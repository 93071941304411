import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faHistory,
    faCubes,
    faList,
    faAlignJustify,
    faCoins,
    faDesktop,
    faAward,
    faSearch,
    faUser,
    faWallet,
    faStar,
    faCalculator,
    faBolt,
    faCaretRight,
    faCaretDown,
    faBook,
    faHandHoldingUsd,
    faLock,
    faUnlock,
    faFire,
    faGem,
    faHammer,
    faMinus,
    faPlus,
    faEquals,
    faExclamation,
    faFeather,
    faTrophy,
    faCheck,
    faTimes,
    faPercentage,
    faTachometerAlt,
    faLongArrowAltRight,
    faLink,
    faScroll,
    faHeading,
    faBold,
} from '@fortawesome/free-solid-svg-icons';

import {
    faHourglass,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faHandshake,
    faEye,
    faMoneyBillAlt,
    faAddressCard,
    faClock,
    faFile,
    faIdCard,
} from '@fortawesome/free-regular-svg-icons';

import {
    faOsi,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faHistory,
    faCubes,
    faList,
    faAlignJustify,
    faCoins,
    faDesktop,
    faAward,
    faHourglass,
    faSearch,
    faUser,
    faWallet,
    faStar,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faCalculator,
    faBolt,
    faHandshake,
    faEye,
    faMoneyBillAlt,
    faCaretRight,
    faCaretDown,
    faBook,
    faHandHoldingUsd,
    faAddressCard,
    faOsi,
    faLock,
    faUnlock,
    faFire,
    faGem,
    faHammer,
    faMinus,
    faPlus,
    faEquals,
    faExclamation,
    faFeather,
    faTrophy,
    faCheck,
    faTimes,
    faPercentage,
    faTachometerAlt,
    faClock,
    faLongArrowAltRight,
    faLink,
    faScroll,
    faFile,
    faIdCard,
    faHeading,
    faBold,
);
